const getDomain = () => {
  const full = window.location.href;
  if (full.indexOf('localhost')) {
    return full
  }
  const fullArray = full.split('.')
  return fullArray[1] + '.' + fullArray[2].substring(0, 3)
}

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCfCjStQchWK6eN3MfP9G5NGr1dsMcb4zs",
  authDomain: "enforceplus-25667.firebaseapp.com",
  projectId: "enforceplus-25667",
  storageBucket: "enforceplus-25667.appspot.com",
  messagingSenderId: "289929693689",
  appId: "1:289929693689:web:2f53c2078987579dd9d528",
  measurementId: "G-Z2JY1TZFD4"
};
//export const WEB_NAV_URL = 'https://client.' + getDomain();
//export const WEB_ADMIN_URL = 'https://admin.' + getDomain();
//export const WEBSITE_URL = 'https://www.' + getDomain();