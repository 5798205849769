import PaymentFail from "./PaymentFail";
import { ActionCreators } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getViolation } from "../../reducers/rootReducer";

const mapStateToProps = state => {
  const { client } = getViolation(state);

  return {
    client,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentFail);