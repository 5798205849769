import * as actionConstants from '../actionConstants';
import { fbstorage as storage, functions } from '../App';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import { httpsCallable } from 'firebase/functions';
import emailjs from '@emailjs/browser';

const moment = require('moment')

/**
 * Get violations by search criteria from functions
 * 
 * @param {*} violationNumber 
 * @param {*} licensePlate 
 * @param {*} licensePlateState 
 * @returns 
 */
export const getViolationBySearchFunction = (violationNumber, licensePlate, licensePlateState) => {
  return async dispatch => {
    dispatch({type: actionConstants.LOADING_VIOLATIONS})
    let violations
    const getViolationsBySearch = httpsCallable(functions, 'getViolationsBySearch')
    violations = await getViolationsBySearch({ violation_number: violationNumber, status: 'unpaid', license_plate: licensePlate, license_plate_state: licensePlateState }).then(result => result.data).catch(error => console.log(error))
    if (!violations || violations.length === 0) {
      dispatch({type: actionConstants.VIOLATIONS_NOT_FOUND})
    } else {
      let vehicle = violations[0].vehicle
      window.location.href = '/violations/' + vehicle
    }
  }
}

/**
 * Get violations by vehicle function
 * 
 * @param {*} vehicle 
 * @returns 
 */
export const getViolationsByVehicleFunction = (vehicle) => {
  return async dispatch => {
    dispatch({type: actionConstants.LOADING_VIOLATIONS})
    const getViolationsByVehicle = httpsCallable(functions, 'getViolationsByVehicleV2')
    let violations = await getViolationsByVehicle({ vehicle: vehicle }).then(result => result.data).catch(error => console.log(error))
    if (!violations || violations.length === 0) {
      dispatch({type: actionConstants.VIOLATIONS_NOT_FOUND, payload: []})
    } else {
      dispatch({type: actionConstants.VIOLATIONS_FOUND, payload: violations})
    }
  }
}

/**
 * Get client by client string function
 * 
 * @param {*} vehicle 
 * @returns 
 */
export const getClientFromStringFunction = (clientString) => {
  return async dispatch => {
    dispatch({type: actionConstants.LOADING_CLIENT})
    const getClientFromString = httpsCallable(functions, 'getClientFromString')
    let client = await getClientFromString({ clientString: clientString }).then(result => result.data).catch(error => console.log(error))
    if (!client) {
      dispatch({type: actionConstants.CLIENT_NOT_FOUND, payload: []})
    } else {
      dispatch({type: actionConstants.CLIENT_FOUND, payload: client})
    }
  }
}

/**
 * Clear client state info
 * 
 * @param {*} vehicle 
 * @returns 
 */
export const clearClient = () => {
  return dispatch => {
    dispatch({type: actionConstants.CLIENT_NOT_FOUND})
  }
}

/**
 * Get violation by violation number function
 * 
 * @param {*} violationNumber 
 * @returns 
 */
export const getViolationByViolationNumberFunction = (violationNumber) => {
  return async dispatch => {
    dispatch({type: actionConstants.LOADING_VIOLATION})
    const getViolationByViolationNumber = httpsCallable(functions, 'getViolationByViolationNumber')
    let violation = await getViolationByViolationNumber({ violation_number: violationNumber }).then(result => result.data).catch(error => console.log(error))
    if (!violation || violation.length === 0) {
      dispatch({type: actionConstants.VIOLATION_NOT_FOUND})
    } else {
      dispatch({type: actionConstants.VIOLATION_FOUND, payload: violation[0]})
    }
  }
}

/**
 * Clear all violation data
 * 
 * @returns 
 */
export const clearViolationData = () => {
  return dispatch => {
    dispatch({type: actionConstants.CLEAR_VIOLATION_DATA})
  }
}

/**
 * Pay violation function
 * 
 * @param {*} token 
 */
export const payViolationFunction = (violation, token, amount, name, email, serviceFee) => {
  return async dispatch => {
    if (token.status === 'OK') {
      const payViolation = httpsCallable(functions, 'payViolation')
      // const accessToken = 'EAAAEPJArDuyMfFYAb5rIsAdfxTaZc4rbPxJkUAaSDwupfB1vO-932BRoSTJJsDc' // SANDBOX
      const accessToken = null
      const paymentResult = await payViolation({ token: token.token, violation: violation, amount: parseFloat(amount).toFixed(2), accessToken, name, email, serviceFee }).then(result => result.data).catch(error => console.log(error))
      if (paymentResult) {
        let emailSent = await emailjs.send("service_cghbh4g","template_195o1zo",{
          violation_number: violation.violation_number,
          location_name: violation.location_name,
          client_name: violation.client_name,
          payment_amount: parseFloat(amount).toFixed(2),
          citation_date: moment(violation.created_at).toString(),
        }, 'Bdnrcb9nkMG24q1Uv');
        if (emailSent) {
          window.location.href = '/violations/' + violation.violation_number + '/payment-success'
        }
      } else if (paymentResult?.errors) {
        window.location.href = '/violations/' + violation.violation_number + '/payment-fail?error=' + paymentResult.errors[0]?.code
      } else {
        window.location.href = '/violations/' + violation.violation_number + '/payment-fail?error=GENERAL_ERROR'
      }
    } else {
      window.location.href = '/violations/' + violation.violation_number + '/payment-fail?error=INVALID_TOKEN'
    }
  }
  
}

/**
 * Submit the violation dispute function
 * 
 * @param {*} violation 
 * @param {*} name 
 * @param {*} email 
 * @param {*} reason 
 * @param {*} evidence1 
 * @param {*} evidence2 
 * @returns 
 */
export const submitDisputeFunction = (violation, name, email, reason, evidence1, evidence2, mobile) => {
  return async dispatch => {
    dispatch({type: actionConstants.SUBMITTING_DISPUTE})
    let evidence = []
    if (evidence1) {
      evidence[0] = await uploadFileToStorage(violation.id, evidence1, 'disputes').then(res => res)
    }
    if (evidence2) {
      evidence[1] = await uploadFileToStorage(violation.id, evidence2, 'disputes').then(res => res)
    }
    const submitDispute = httpsCallable(functions, 'submitDispute')
    let submitted = await submitDispute({ violation, name, email, reason, evidence, mobile }).then(result => result.data).catch(error => console.log(error))
    if (submitted) {
      dispatch({type: actionConstants.SUBMITTED_DISPUTE})
      emailjs.send("service_cghbh4g", "template_9riurfh", {
          violation_number: violation.violation_number,
          to_email: email,
        }, 'Bdnrcb9nkMG24q1Uv');
      if (violation?.admin_string) {
        emailjs.send("service_cghbh4g", "template_m3o5uf9", {
          violation_number: violation.violation_number,
          to_email: violation.admin_string,
          location_name: violation.location_name,
          name: name,
          link: "https://app.enforceplus.com/citation/" + violation.violation_number
        }, 'Bdnrcb9nkMG24q1Uv');
      }
    } else {
      dispatch({type: actionConstants.SUBMIT_DISPUTE_FAILED})
    }
  }
}

/**
 * Upload evidence file
 * 
 * @param {*} violationId 
 * @param {*} file 
 * @param {*} targetPath 
 * @returns 
 */
const uploadFileToStorage = async (violationId, file, targetPath) => {
  const storageRef = ref(storage, `${targetPath}/${violationId}/${file.name}`)
  const snapshotRef = await uploadBytes(storageRef, file).then(snapshot => snapshot.ref)
  return getDownloadURL(snapshotRef).then((url) => ({url, name: file.name}));
}