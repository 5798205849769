import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CitationLayout = ({ children, noNavbar, noFooter }) => {
  
  return (
    <Container fluid style={{backgroundColor: 'white', overflowX: 'hidden'}}>
      <Row>
        <Col style={{paddingLeft: 8, paddingRight: 8, maxWidth: 380, marginLeft: 'auto', marginRight: 'auto'}}>
          {children}
        </Col>
      </Row>
    </Container>
  )
};

export default CitationLayout;
