import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getPrimaryColor, getLogo } from "../Tools";

const enforceplusLogo = require('../images/enforceplus-logo.png')

const DefaultLayoutInner = ({children, client}) => {
  
  return (
    <Container fluid style={{overflowX: 'hidden'}}>
      <Row>
        <Col style={{paddingLeft: 8, paddingRight: 8, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto'}}>
          <div style={{paddingTop: 65}}>
            <Row>
              <Col>
                <div style={{borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: getPrimaryColor(client), borderColor: getPrimaryColor(client)}}>
                  <Row>
                    <Col>
                      <div style={{display: 'flex', border: '1px solid #EEEEEE', padding: 10, width: 100, height: 100, borderRadius: 50, backgroundColor: 'white', marginTop: -50, overflow: 'hidden', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', justifyContent: 'center'}}>
                        <img src={getLogo(client)} style={{width: '100%', height: '100%', borderColor: 'white', objectFit: 'contain'}}/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-center' style={{paddingTop: 22, paddingBottom: 30}}>
                      <span style={{color: 'white', fontSize: 20}}>Pay or dispute</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{backgroundColor: 'white', borderBottomLeftRadius: 5, borderBottomRightRadius: 5}}>
                  <Row>
                    <Col style={{paddingTop: 16, paddingLeft: 24, paddingRight: 24}}>
                      {children}
                    </Col>
                  </Row>
                  <Row>
                    <Col className='text-center' style={{paddingBottom: 50}}>
                      <img src={enforceplusLogo} style={{height: 53, width: 83}}/>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            
            <Row style={{paddingTop: 16}}>
              <Col className="text-center"><a href="https://enforceplus.com/privacy-policy/" target="_blank" style={{textDecoration: 'none', color: 'grey'}}><span style={{fontSize: 12, color: 'grey'}}>Privacy policy</span></a></Col>
              <Col className="text-center"><a href="https://enforceplus.com/#contact" target="_blank" style={{textDecoration: 'none', color: 'grey'}}><span style={{fontSize: 12, color: 'grey'}}>Help</span></a></Col>
              <Col className="text-center"><a href="https://enforceplus.com/terms-and-conditions/" target="_blank" style={{textDecoration: 'none', color: 'grey'}}><span style={{fontSize: 12, color: 'grey'}}>Terms of use</span></a></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
};

export default DefaultLayoutInner;
