export const SET_ACCESSIBILITY = 'SET_ACCESSIBILITY';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const VIOLATIONS_NOT_FOUND = 'VIOLATIONS_NOT_FOUND';
export const VIOLATIONS_FOUND = 'VIOLATIONS_FOUND';
export const LOADING_VIOLATIONS = 'LOADING_VIOLATIONS';
export const VIOLATION_NOT_FOUND = 'VIOLATION_NOT_FOUND';
export const VIOLATION_FOUND = 'VIOLATION_FOUND';
export const LOADING_VIOLATION = 'LOADING_VIOLATION';
export const CLEAR_VIOLATION_DATA = 'CLEAR_VIOLATION_DATA';

export const LOADING_CLIENT = 'LOADING_CLIENT';
export const CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND';
export const CLIENT_FOUND = 'CLIENT_FOUND';

export const SUBMITTING_DISPUTE = 'SUBMITTING_DISPUTE';
export const SUBMITTED_DISPUTE = 'SUBMITTED_DISPUTE';
export const SUBMIT_DISPUTE_FAILED = 'SUBMIT_DISPUTE_FAILED';
