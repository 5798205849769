// @flow weak
import * as actionConstants from '../actionConstants';

// Reducer
const DEFAULT_STATE = {
  violations: null,
  loadingViolations: false,
  loadedViolations: false,
  loadingViolationsFailed: false,
  violation: null,
  loadingViolation: false,
  loadedViolation: false,
  loadingViolationFailed: false,
  submittingDispute: false,
  submittedDispute: false,
  submitDisputeFailed: false,
  client: null,
  loadingClient: false,
  loadedClient: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case actionConstants.LOADING_VIOLATIONS:
      return {
        ...state, loadingViolations: true, loadedViolations: false, loadingViolationsFailed: false,
      };
    case actionConstants.VIOLATIONS_FOUND:
      return {
        ...state, loadingViolations: false, loadedViolations: true, loadingViolationsFailed: false, violations: action.payload,
      };
    case actionConstants.VIOLATIONS_NOT_FOUND:
      return {
        ...state, loadingViolations: false, loadedViolations: true, loadingViolationsFailed: true, violations: []
      };
    case actionConstants.LOADING_VIOLATION:
      return {
        ...state, loadingViolation: true, loadedViolation: false, loadingViolationFailed: false,
      };
    case actionConstants.VIOLATION_FOUND:
      return {
        ...state, loadingViolation: false, loadedViolation: true, loadingViolationFailed: false, violation: action.payload,
      };
    case actionConstants.VIOLATION_NOT_FOUND:
      return {
        ...state, loadingViolation: false, loadedViolation: false, loadingViolationFailed: true,
      };
    case actionConstants.SUBMITTING_DISPUTE:
      return {
        ...state, submittingDispute: true, submittedDispute: false, submitDisputeFailed: false,
      }; 
    case actionConstants.SUBMITTED_DISPUTE:
      return {
        ...state, submittingDispute: false, submittedDispute: true, submitDisputeFailed: false,
      };
    case actionConstants.SUBMIT_DISPUTE_FAILED:
      return {
        ...state, submittingDispute: false, submittedDispute: false, submitDisputeFailed: true,
      };  
    case actionConstants.LOADING_CLIENT:
      return {
        ...state, loadingClient: true, loadedClient: false, client: null,
      }  
    case actionConstants.CLIENT_FOUND:
      return {
        ...state, loadingClient: false, loadedClient: true, client: action.payload,
      }
    case actionConstants.CLIENT_NOT_FOUND:
      return {
        ...state, loadingClient: false, loadedClient: false, client: null,
      }  
    case actionConstants.CLEAR_VIOLATION_DATA:
      return DEFAULT_STATE;   
    default:
      return state;
  }
}

// Selectors (mapStateToP rops)
export const getViolation = ({
  loadingViolations,
  loadedViolations,
  loadingViolationsFailed,
  violations,
  loadingViolation,
  loadedViolation,
  loadingViolationFailed,
  violation,
  submittingDispute,
  submittedDispute,
  submitDisputeFailed,
  client,
  loadingClient,
  loadedClient,
}) => ({
  loadingViolations,
  loadedViolations,
  loadingViolationsFailed,
  violations,
  loadingViolation,
  loadedViolation,
  loadingViolationFailed,
  violation,
  submittingDispute,
  submittedDispute,
  submitDisputeFailed,
  client,
  loadingClient,
  loadedClient,
});
