const enforceplusIcon = require('../images/enforceplus-icon.png')

export const getPrimaryColor = (client) => {
  if (client?.primary_color && client?.primary_color?.length === 7) {
    return client?.primary_color
  }
  return '#1D9E68'
}

export const getLogo = (client) => {
  if (client?.logo && client?.logo?.length > 8) {
    return client?.logo
  }
  return enforceplusIcon
}