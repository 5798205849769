import Citation from "./Citation";
import { ActionCreators } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getViolation } from "../../reducers/rootReducer";

const mapStateToProps = state => {
  const { loadingViolation, loadedViolation, loadingViolationFailed, violation } = getViolation(state);

  return {
    loadingViolation,
    loadedViolation,
    loadingViolationFailed,
    violation
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Citation);