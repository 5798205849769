import React from "react";
import { Navigate } from "react-router-dom";

// Layout Types
import DefaultLayout from "./layouts/Default";
import CitationLayout from "./layouts/Citation";

import Main from "./containers/Main/index";
import Violations from "./containers/Violations/index";
import PaymentSuccess from "./containers/PaymentSuccess/index";
import PaymentFail from "./containers/PaymentFail/index";
import Citation from "./containers/Citation/index";

export default [
  {
    path: "/citation/:violationNumber",
    exact: true,
    layout: CitationLayout,
    component: Citation
  },
  {
    path: "/violations/:violationNumber/payment-success",
    exact: true,
    layout: DefaultLayout,
    component: PaymentSuccess
  },
  {
    path: "/violations/:violationNumber/payment-fail",
    exact: true,
    layout: DefaultLayout,
    component: PaymentFail
  },
  {
    path: "/violations/:vehicleId",
    exact: true,
    layout: DefaultLayout,
    component: Violations
  },
  {
    path: "/:clientString/:vehicleId",
    exact: true,
    layout: DefaultLayout,
    component: Violations
  },
  {
    path: "/:clientString",
    exact: true,
    layout: DefaultLayout,
    component: Main
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: Main
  },
];
