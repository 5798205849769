import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import DefaultLayoutInner from '../../components/DefaultLayoutInner';
import { getPrimaryColor, getLogo } from '../../Tools';

const PaymentFail = (props) => {
  const [client] = useState(props.client)
  return (
    <DefaultLayoutInner client={client}>
      <Row style={{minHeight: 500}}>
        <Col className='text-center'>
          <span style={{fontSize: 18, fontWeight: 'bold'}}>Sorry!</span><br /><br />
          <span>Your payment has failed</span><br /><br />
          <Button
            className='btn btn-lg'
            style={{color: 'white', fontSize: 18, backgroundColor: getPrimaryColor(client), borderColor: getPrimaryColor(client)}}
            onClick={() => window.history.back()}>TRY AGAIN</Button>
        </Col>
      </Row>
    </DefaultLayoutInner>
  )
}

export default PaymentFail;
