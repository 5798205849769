import Main from "./Main";
import { ActionCreators } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getViolation } from "../../reducers/rootReducer";

const mapStateToProps = state => {
  const { loadingViolations, loadedViolations, loadingViolationsFailed, violations, client, loadingClient } = getViolation(state);

  return {
    loadingViolations,
    loadedViolations,
    loadingViolationsFailed,
    violations,
    client,
    loadingClient
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);